import AudienceCampaignClient from "./AudienceCampaignClient";
import AudienceCampaignConfiguration from "./AudienceCampaignConfiguration";
import AudienceCampaignDuration from "./AudienceCampaignDuration"

/**
 * @typedef AudienceCampaignType
 * @property {Number} id
 * @property {string} title
 * @property {string} status
 * @property {string} type
 * @property {string} currencyCode
 * @property {AudienceCampaignDuration} duration
 * @property {AudienceCampaignClient} client
 * @property {AudienceCampaignConfiguration|null} configuration
 */

/** @type AudienceCampaignType */
export default class AudienceCampaign {
    constructor(campaignJson) {
        this.id = campaignJson.id
        this.title = campaignJson.title
        this.status = campaignJson.status
        this.type = campaignJson.type
        this.currencyCode = campaignJson.currency,
        this.duration = new AudienceCampaignDuration(campaignJson.duration)
        this.client = new AudienceCampaignClient(campaignJson.client)
        this.configuration = campaignJson.configuration ? new AudienceCampaignConfiguration(campaignJson.configuration) : null
    }

    static defaultNew() {
        return new this({
            title: 'New campaign',
            duration: {
                start_date: '',
                end_date: '',
            },
            client: {
                name: '',
                id: null,
            },
            configuration: {
                uses: {
                    ad_server: false,
                    google_analytics: false,
                }
            }
        })
    }
}