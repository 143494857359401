<template>
    <div>
        <form @submit.prevent>
            <div>
                <base-input :disabled="isEditingDisabled" label="Campaign name" v-model="selectedCampaign.title" />
            </div>

            <div class="mb-4 d-flex flex-column">
                <label class="base-input__label">Advertiser</label>
                <el-autocomplete
                    :disabled="isEditingDisabled"
                    v-model="selectedCampaign.client.name"
                    :fetch-suggestions="searchForClients"
                    @select="(item) => selectedCampaign.client = item.client"
                />
            </div>

            <div>
                <label class="base-input__label">Campaign type</label>
                <el-select :disabled="isEditingDisabled" v-model="selectedCampaign.type">
                    <el-option
                        v-for="type in possibleCampaignTypes"
                        :key="type.value"
                        :value="type.value"
                        :label="type.label"
                    />
                </el-select>
            </div>

            <div class="mb-4">
                <label class="base-input__label">Campaign duration</label>
                <el-date-picker
                    :disabled="isEditingDisabled"
                    v-model="selectedCampaignDurationIntermediate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                />
            </div>

            <div v-if="selectedCampaign.configuration">
                <div>
                    <label class="base-input__label">Formats</label>
                    <el-select :disabled="isEditingDisabled" v-model="selectedCampaign.configuration.formats" multiple>
                        <el-option
                            v-for="format in possibleFormats"
                            :key="format.value"
                            :value="format.value"
                            :label="format.label"
                        />
                    </el-select>
                    <PopupInput title="Need additional formats?">
                        <base-textarea :disabled="isEditingDisabled" placeholder="Specify additional formats here" v-model="selectedCampaign.configuration.additionalFormats" class="mt-sm" />
                    </PopupInput>
                </div>
                
                <div>
                    <base-input :disabled="isEditingDisabled" label="Campaign budget" v-model="selectedCampaign.configuration.budget" type="number" :formatter="formatCost" />
                </div>

                <div>
                    <base-input :disabled="isEditingDisabled" label="Target" v-model="selectedCampaign.configuration.targetAudience" />
                </div>

                <div v-if="selectedCampaign.type == 'PERFORMANCE'">
                    <div>
                        <base-input :disabled="isEditingDisabled" label="Target CPM" v-model="selectedCampaign.configuration.cpm" type="number" />
                    </div>

                    <div>
                        <base-input :disabled="isEditingDisabled" label="Conversion Attribution Window post click" v-model="selectedCampaign.configuration.postClickTracking" type="number" />
                    </div>

                    <div>
                        <base-input :disabled="isEditingDisabled" label="Conversion Attribution Window post view" v-model="selectedCampaign.configuration.postViewTracking" type="number" />
                    </div>

                    <div class="my-sm d-flex align-items-center justify-content-between">
                        <label>Uses AdServer?</label>
                        <el-switch :disabled="isEditingDisabled" v-model="selectedCampaign.configuration.uses.adServer"></el-switch>
                    </div>

                    <div class="d-flex align-items-center justify-content-between">
                        <label>Uses Google Analytics?</label>
                        <el-switch :disabled="isEditingDisabled" v-model="selectedCampaign.configuration.uses.googleAnalytics"></el-switch>
                    </div>
                </div>

                <div class="my-sm">
                    <base-textarea :disabled="isEditingDisabled" label="Notes" v-model="selectedCampaign.configuration.notes" :setStyle="{minHeight:'200px'}" />
                </div>

                <div class="my-sm d-flex flex-column" v-if="!isEditingDisabled">
                    <base-button @click="submitCampaign">Save</base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { apiHandler } from "@/util/errorHandling";

import { BaseButton, BaseTextarea, BaseInput } from '@/components'
import PopupInput from "@/pages/Tools/BlogPostParagraphs/PopupInput.vue"
import { Select, Option, DatePicker, Switch, Autocomplete } from "element-ui"


import AudienceCampaign from '../Entities/AudienceCampaign'
import AudienceCampaignClient from "../Entities/AudienceCampaignClient"
import { formatCost, formatDate } from "../Helpers/formatters"

export default {
    components: {
        BaseButton,
        BaseTextarea,
        BaseInput,
        PopupInput,
        [Select.name]: Select,
        [Option.name]: Option,
        [DatePicker.name]: DatePicker,
        [Switch.name]: Switch,
        [Autocomplete.name]: Autocomplete,
    },
    props: {
        isManager: {
            type: Boolean,
            required: false,
            default: false,
        },
        canEditCampaign: {
            type: Boolean,
            required: false,
            default: false,
        },
        /** @type {AudienceCampaign|null} */
        campaign: {
            type: AudienceCampaign,
            required:false,
            default: () => null,
        },
        /** @type {AudienceCampaignClient[]} */
        availableClients: {
            type: Array,
            required: false,
            default: () => [],
        },
        /** @type {{label: string, value: string}[]} */
        possibleCampaignTypes: {
            type: Array,
            required: false,
            default: () => [],
        },
        /** @type {{label: string, value: string}[]} */
        possibleFormats: {
            type: Array,
            required: false,
            default: () => [],
        }
    },
    data() {
        return {
            /** @type {AudienceCampaign} */
            selectedCampaign: null,
            // The datepicker component uses a different format than our object,
            // this is an intermediate variable used to handle conversions
            selectedCampaignDurationIntermediate: [],
        }
    },
    computed: {
        apiBaseUrl() {
            return this.isManager ? 'tools/audience-campaigns/manager' : 'tools/audience-campaigns/client'
        },
        isEditingDisabled() {
            return !this.canEditCampaign && this.campaign
        }
    },
    methods: {
        formatCost,
        formatDate,
        async submitCampaign() {
            await apiHandler(async () => {
                this.selectedCampaign.client = await this.getOrCreateClient()

                this.$forceUpdate()

                if (this.campaign) {
                    await this.updateCampaign()
                } else {
                    await this.createNewCampaign()
                }

                this.$emit('submit', this.selectedCampaign)
            })
        },
        /**
         * @returns {FormData}
         */
        buildCampaignPayload() {
            const payload = new FormData()
            payload.append('campaign_id', this.selectedCampaign.id)
            payload.append('client_id', this.selectedCampaign.client.id)
            payload.append('title', this.selectedCampaign.title)
            payload.append('type', this.selectedCampaign.type)
            payload.append('start_date', this.selectedCampaign.duration.startDate)
            payload.append('end_date', this.selectedCampaign.duration.endDate)
            if (this.selectedCampaign.configuration) {
                payload.append('budget', this.selectedCampaign.configuration.budget)
                payload.append('target', this.selectedCampaign.configuration.targetAudience)
                payload.append('cpm', this.selectedCampaign.configuration.cpm)
                this.selectedCampaign.configuration.formats?.forEach(format => {
                    payload.append('formats[]', format)
                });
                payload.append('additional_formats', this.selectedCampaign.configuration.additionalFormats)
                payload.append('notes', this.selectedCampaign.configuration.notes)
                payload.append('post_click_tracking', this.selectedCampaign.configuration.postClickTracking)
                payload.append('post_view_tracking', this.selectedCampaign.configuration.postViewTracking)
                payload.append('uses_adserver', this.selectedCampaign.configuration.uses.adServer)
                payload.append('uses_google_analytics', this.selectedCampaign.configuration.uses.googleAnalytics)
            }

            return payload
        },
        async createNewCampaign() {
            const payload = this.buildCampaignPayload()

            await this.$axios.post(this.apiBaseUrl + '/create-campaign', payload)
        },
        async updateCampaign() {
            const payload = this.buildCampaignPayload()
            
            await this.$axios.post(this.apiBaseUrl + '/update-campaign', payload)
        },
        /**
         * @returns {AudienceCampaignClient}
         */
        async getOrCreateClient() {
            const clientsWithExactSameNameAsCurrentOne = this.availableClients
                .filter(client => client.name === this.selectedCampaign.client.name)

            if (clientsWithExactSameNameAsCurrentOne.length > 0) {
                return clientsWithExactSameNameAsCurrentOne[0]
            }

            return await this.createNewClient()
        },
        async createNewClient() {
            const payload = new FormData()
            payload.append('client_name', this.selectedCampaign.client.name)
            
            const response = await this.$axios.post(this.apiBaseUrl + '/create-client', payload)

            this.$emit('created-new-client', response.data.data)

            return response.data.data
        },
        /**
         * @param {string} queryString
         * @param {Function} resultCallback 
         */
        searchForClients(queryString, resultCallback) {
            const results = this.availableClients
                .filter(client => {
                    if (!queryString || queryString.trim().length === 0) {
                        return true
                    }
                    return client.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
                })
                .map(client => {
                    return {
                        value: client.name,
                        client
                    }
                })
            resultCallback(results)
        },
    },
    beforeMount () {
        this.selectedCampaign = this.campaign ? JSON.parse(JSON.stringify(this.campaign)) : AudienceCampaign.defaultNew()
        if (this.selectedCampaign.duration) {
            this.selectedCampaignDurationIntermediate = [
                this.selectedCampaign.duration.startDate,
                this.selectedCampaign.duration.endDate,
            ]
        }
    },
    watch: {
        selectedCampaignDurationIntermediate: {
            handler(newValue) {
                this.selectedCampaign.duration.startDate = formatDate(newValue[0])
                this.selectedCampaign.duration.endDate = formatDate(newValue[1])
            }
        }
    }
}
</script>

<style scoped>
.base-input__label {
    display: block !important;
}
</style>
